
import { defineComponent, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
    name: "auth",
    components: {},
    data() {
        return {
            getLocaleLang: function () {
                return localStorage.locale;
            },
        };
    },
    methods: {
        setLocale: function (locale) {
            localStorage.locale = locale;
            location.reload();
        },
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        onMounted(() => {
            store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
            if (store.getters.isUserAuthenticated) {
                router.push({ name: "dashboard" });
            }
        });

        onUnmounted(() => {
            store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
        });
        watch(
            () => route.path,
            () => {
                if (store.getters.isUserAuthenticated) {
                    router.push({ name: "dashboard" });
                }
            }
        );
    },
});
